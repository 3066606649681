.calendar-container {


    min-height: calc(100vh - 10px);
    /*min-height: 100vh;display: flex;
  flex-direction: column;*/
    position: relative;
    z-index: 1;
    padding-bottom: 0;
}

.dir-pane {
    position: absolute;
    background-color: #ddd;
    align-self: center;
    z-index: 20;
    border: 1px solid #aaa;
    animation-duration: 0.6s;
}

.dir-pane.left {
    border-radius: 0 5px 5px 0;
    left: 0px;
}

@keyframes slideoutFromLeft {
    from {
        margin-left: -30px;
    }

    to {
        margin-left: 0;
    }
}

.dir-pane.right {
    border-radius: 5px 0 0 5px;
    right: 0px;
    animation-name: slideoutFromRight;
}

@keyframes slideoutFromRight {
    from {
        width: 0;
    }

    to {
        width: 30px;
    }
}

.dir-pane ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.dir-pane ul li {
    list-style-type: none;
    padding: 0;
    overflow: hidden;
}

.dir-pane ul li button {
    border: none;
    background-color: #ddd;
    cursor: pointer;
    font-size: 43px;
    color: #aaa;
    padding: 30px 0px;
}


.cta-pane {
    border-radius: 5px 5px 0 0;
    position: absolute;
    bottom: 0;
    display: inline-block;
    background-color: transparent;
    text-align: center;
    align-self: center;
    z-index: 20;
    padding: 5px;
    width: auto;
    max-width: 100%;
    border: 1px solid #aaa;
    left: 50%;
    transform: translateX(-50%);
}

.cta-pane ul {
    display: inline-block;
    padding: 0;
    margin: 0;
}

.cta-pane ul li {
    display: inline-block;
    padding: 5px 8px;
}

.cta-pane ul li button {
    display: inline-block;
    padding: 10px;
    font-size: 23px;
    background-color: transparent;
    outline: 0;
    border: none;
    cursor: pointer;
}


.curver {
    border: 1px solid #999;
    border-radius: 6px;
    overflow: hidden;
}

.cells-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}




.cells-container:hover > .dir-pane.left {
    animation-name: slideoutFromLeft;
}

.cells-container:hover > .dir-pane.right {
    animation-name: slideoutFromRight;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.summary-cell {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-grow: 1;
    font-size: 18px;
    font-weight: 900;
    padding: 14px 10px;
    color: #fff;
}

.week-bar {
    display: flex;
    flex-direction: row;
    border-top: 1px solid #999;
}


.week-cell {
    position: relative;
    display: flex;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.01em;
    width: calc(100% / 7);
    padding: 15px 0;
    border-right: 1px solid #777;
    background-color: #aaa;
}

.day-cell {
    position: relative;
    display: flex;
    width: calc(100% / 7);
    padding: 0;
    height: 55px;
    border-right: 1px solid #999;
}

.week-cell:last-of-type,
.day-cell:last-of-type {
    border-right: none;
}

.day-cell.today_active .day-span {
    color: #fff;
    background-color: #037652;
}

.day-cell.today_active:hover .day-span {
    color: #fff;
    background-color: #7b2f02;
}

.day-cell.today_active .trad-span {
    color: #fff;
}



.day-cell.disabled {
    color: #fefefe;
    cursor: default;
    pointer-events: none;
}

.day-cell.disabled .day-span {
    color: #555 !important;
}

.day-cell:hover {
    background-color: #037652;
}

.day-cell:hover .day-span {
    color: #fff;
}

.day-cell:hover .trad-span {
    color: #fff;
}


.day-span {
    display: flex;
    flex: 1 1;
    justify-content: center;
    align-items: center;
    align-self: center;
    font-size: 1.2rem;
    font-weight: 500;
    height: 100%;
    width: 100%;
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
    color: #fff;
}


.trad-span {
    position: absolute;
    bottom: 0;
    right: 0;
    display: inline-block;
    padding: 1px 3px 3px 0;
    margin-top: 4px;
    line-height: 1 !important;
    color: rgb(246, 213, 26);
    font-size: 75%;
}

@media screen and (max-width: 500px) {

    .week-cell {
        font-weight: 500;
        padding: 15px 0;
        font-size: 0.9rem;
    }

    .trad-span {
        font-size: 70% !important;
    }
}


.date-class {
    font-size: 90%;
    font-style: italic;
    color: #666;
}

.modal-width {
    min-width: 450px;
    padding: 30px;
}

.h3-underline {
    margin-bottom: 5px;
    border-bottom: 1px solid #ddd;
    padding: 0;
}


.date-card {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    border: 1px solid #444;
    padding: 10px 0;
    border-radius: 8px;
}

.date-point {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
}

.desc-span {
    display: flex;
    font-size: 16px;
    font-weight: 400;
    justify-content: center;
    text-transform: uppercase;
    align-items: center;
    padding: 5px 10px 5px 10px;
    flex-direction: column;
}

.digit-span {
    font-size: 50px;
    font-weight: 200;
    padding-top: 10px;
    justify-content: center;
    line-height: 1;
}

.trad_illus {
    color: rgb(255, 44, 86);
    font-weight: 900;
}

.dot-span {
    font-size: 100px;
    font-weight: 200;
    padding: 0;
}





.exp-div {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #efefef;
}



.full-width {
    width: 100%;
    position: relative;
}

.full-height {
    height: 100vh;
    position: relative;
}


.sm-show-inline,
.sm-show-inline-block,
.sm-show-block,
.sm-show-flex,
.sm-show-inline-flex {
    display: none !important;
}

.sm-hide-inline {
    display: inline !important;
}

.sm-hide-inline-block {
    display: inline-block !important;
}

.sm-hide-block {
    display: block !important;
}

.sm-hide-flex {
    display: flex !important;
}

.sm-hide-inline-flex {
    display: inline-flex !important;
}

@media screen and (max-width: 500px) {

    .sm-hide-inline,
    .sm-hide-inline-block,
    .sm-hide-block,
    .sm-hide-flex,
    .sm-hide-inline-flex {
        display: none !important;
    }

    .sm-show-block {
        display: block !important;
    }

    .sm-show-inline {
        display: inline !important;
    }

    .sm-show-inline-block {
        display: inline-block !important;
    }

    .sm-show-inline-flex {
        display: inline-flex !important;
    }

    .sm-show-flex {
        display: flex !important;
    }
}





.smix {
    font-size: 1.02rem;
}

@media screen and (max-width: 451px) {
    .modal-width {
        min-width: 100% !important;
        max-width: 100% !important;
        padding: 20px;
    }

    .desc-span {
        font-size: 13px;
    }

    .digit-span {
        font-size: 30px;
    }

    .smix {
        font-size: 0.6rem;
    }

}

.close-button-cover {
    display: flex;
    align-content: flex-start;
    flex-direction: column;
    margin-top: 10px;
}

.close-modal {
    outline: 0;
    background-color: #fe6f16;
    color: #fff;
    padding: 15px 25px;
    border-radius: 30px;
    display: flex;
    text-align: center;
    appearance: none;
    line-height: 1;
    -moz-appearance: none;
    -webkit-appearance: none;
    border: 0px solid transparent;
    cursor: pointer;
}

.close-modal:hover {
    background-color: #f62a06;
}

.search-link {
    color: #fff;
    cursor: pointer;
}