.page-main {
    position: relative;
    background-size: cover;
    background-color: #111;
    min-height: 80px;
    background: url(/public/images/bgx.jpg);
}

.page-main-full {
    position: relative;
    background-size: cover;
    min-height: 100vh;
}


.page-top-content {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 auto;
    margin-top: 30px;
    width: 90%;
    z-index: 1;
}

@media screen and (max-width: 600px) {
    .page-top-content {
        display: block;
        padding: 20px 30px;
        margin-top: 10px;
        width: 100%;
    }

    .page-intro-div h1 {
        font-size: 35px;
        line-height: 1.4;
        margin-bottom: 10px;
    }

    .illustration-container {
        min-width: 100%;
    }
}

.page-intro-div {}

.page-intro-div h1 {
    font-weight: 900;
    color: #fff;
    font-size: 2rem;
    line-height: 1.2;
    margin-bottom: 10px;
}

.page-intro-div p {
    font-weight: 700;
    color: #fff;
    font-size: 1.1rem;
    line-height: 1.2;
    margin: 0;
    padding: 0;
}

.page-intro-div nav {
    color: #fff;
    margin-bottom: 10px;
}

.page-intro-div nav a {
    text-decoration: underline;
    color: rgb(165, 122, 42);
}

.page-intro-div p {
    font-size: 21px;
    font-weight: 400;
    line-height: 30px;
}

.illustration-container {
    min-width: 50%
}