.dashboard-container {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.dashboard-header-container {
    height: 60px;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #ddd;
    align-items: center;
}

.dashboard-body-container {
    display: flex;
    flex-direction: row;
    flex: 1;
    max-height: calc(100% - 60px);
}

.dashboard-side-nav {
    background-color: #fefefe;
    border-right: 1px solid #ddd;
    width: 300px;
    overflow-y: auto;
    max-height: 100%;
    transition: .3s;
}

.mobile-ham {
    display: none;
    padding-left: 15px;
}

.mobile-ham a,
.mobile-ham button {
    font-size: 23px;
    display: inline-flex;
    width: 30px;
    height: 30px;
    align-items: center;
    background-color: transparent;
    border: none;
}

.mobile-ham a:hover,
.mobile-ham button:hover {
    color: orange;
}

.desk-show {
    display: inline-flex;
}

.expand-width {
    width: 300px !important;
}

@media screen and (max-width: 600px) {
    .dashboard-side-nav {
        width: 1px;
    }

    .mobile-ham {
        display: inline-block;
    }

    .desk-show {
        display: none;
    }
}

.ham-logo {
    height: 40px;
    margin-left: 10px;
}

.ham-logo img {
    height: 100%;
}

.content-container {
    overflow-y: auto;
    flex: 1;
    position: relative;
    overflow-y: auto;
    max-height: 100%;
    display: flex;
}

.page-content {
    display: flex;
    height: 100%;
    padding-bottom: 20px;
    flex: 1;
    position: relative;
}

ul.side-nav-list {
    list-style-type: none;
    padding: 20px 20px 20px 30px;
}

ul.side-nav-list li {
    display: block;
    padding: 0;
    margin: 0 0 3px 0;
}

ul.side-nav-list li a {
    display: flex;
    align-items: center;
    transition: .5s;
    padding: 8px 10px;
    font-weight: 700;
    color: #444;
    border-radius: 5px;
    font-size: 0.9rem;
    text-transform: uppercase;
}

ul.side-nav-list li a .MuiSvgIcon-root,
ul.side-nav-list li a svg {
    color: rgb(5, 76, 98);
}

ul.side-nav-list li a.logout .MuiSvgIcon-root,
ul.side-nav-list li a.logout svg {
    color: rgb(255, 30, 0);
}

ul.side-nav-list li ul {
    padding-left: 10px;
}

ul.side-nav-list li ul li a {
    padding: 3px 8px !important;
    font-weight: 500;
    display: block;
    color: #555;
    font-size: 98%;
}

ul.side-nav-list li a:hover,
ul.side-nav-list li a.active {
    background-color: rgb(220, 237, 240);
    color: #222;
}

.bordered-dashed {
    border: 2px dashed red;
}

.absolute {
    position: absolute;
}

.top {
    top: 0;
}

.bottom {
    bottom: 0;
}

.full {
    width: 100%;
}