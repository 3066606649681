@tailwind base;

@tailwind components;

@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;700&display=swap');

html,
body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  font-family: "Work Sans";
  font-size: 1.05rem;
  line-height: 1.5;
  font-weight: 400;
  color: #2B292D;
  background: #f5f5f5;
}

* {
  box-sizing: border-box !important;
}

main#root {
  min-height: 100vh;
  width: 100%;
  overflow-y: auto;
}

a {
  color: #066f8f;
  outline: 0;
}

p {
  margin-top: 5px;
  margin-bottom: 15px;
}

.h100 {
  height: 100vh;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  font-weight: bold;
  font-family: "Work Sans";
}

h1 {
  font-size: 2em;
}

h2 {
  font-size: 1.3em;
}

input,
button,
textarea,
select {
  font-family: "Roboto", sans-serif;
}

.container {
  margin: 0 auto;
  width: 80%;
  display: block;
}

.container-wide {
  margin: 0 auto;
  width: 90%;
  display: block;
}

@media screen and (max-width: 960px) {

  .container,
  .container-wide {
    width: 100%;
    padding: 0 10px;
  }
}

.vh-header {
  height: calc(100vh - 100px);
}

a {
  text-decoration: none;
}

a[href],
button,
label {
  cursor: pointer !important;
}

.flex {
  display: flex;
}

.flex-resp {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-row-resp {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-col-resp {
  flex-direction: column;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.flex-grow,
.flex-grow-1 {
  flex-grow: 1;
}

.flex-grow-0 {
  flex-grow: 0 0;
}

.flex-1 {
  flex: 1;
  display: flex;
}

.w-50 {
  width: 50%;
  min-width: 50%;
}

.w-50-resp {
  width: 50%;
  min-width: 50%;
  max-width: 50%;
}

.w-60-resp {
  width: 60%;
  min-width: 60%;
  max-width: 60%;
}

.w-70-resp {
  width: 70%;
  min-width: 70%;
  max-width: 70%;
}

.w-80-resp {
  width: 80%;
  min-width: 80%;
  max-width: 80%;
}

.patch-w-50 {
  width: 50%;
}

@media screen and (max-width: 600px) {

  .w-50-resp,
  .w-60-resp,
  .w-70-resp,
  .w-80-resp {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
}

.spacer {
  flex: 1;
}

.px5 {
  padding-left: 5px;
  padding-right: 5px;
}

.px10 {
  padding-left: 10px;
  padding-right: 10px;
}

.px15 {
  padding-left: 15px;
  padding-right: 15px;
}

.px20 {
  padding-left: 20px;
  padding-right: 20px;
}

.px30 {
  padding-left: 30px;
  padding-right: 30px;
}

.form-main {
  min-height: 100vh;
  background-size: cover;
  display: flex;
  flex-direction: row;
}

.design-side {
  display: flex;
  flex: 1;
  background: #334d50;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to top,
      #152527,
      #334d50);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to top,
      #152527,
      #334d50);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  z-index: 0 !important;
  background-repeat: no-repeat;
  background-blend-mode: difference;
  animation: animatedBackground 35s linear infinite alternate;
}

.form-side {
  display: flex;
  flex: 1;
  background-color: #fff;
  justify-content: center;
  align-items: center;
}

.login-pane {
  background-color: #fff;
  border-radius: 8px;
  border-radius: 4px;
  box-shadow: 0 2px 1px -1px #0003, 0 1px 1px 0 #00000024, 0 1px 3px 0 #0000001f;
  color: #000000de;
  max-width: 400px;
  min-width: 400px;
  overflow: hidden;
  padding: 40px 25px;
  transition: box-shadow .3s cubic-bezier(.4, 0, .2, 1) 0ms;
}

@media screen and (max-width: 400px) {
  .login-pane {
    min-width: 100%;
    padding: 30px 15px;
  }
}

.input {
  position: relative;
  margin-bottom: 20px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.input > label {
  position: absolute;
  top: -10px;
  left: 10px;
  display: inline-block;
  background-color: #fff;
  padding: 3px 5px;
  line-height: 0.8;
  font-size: 90% !important;
  border-radius: 3px;
  font-family: "Roboto", sans-serif;
}

.input > label.textfeld-control {
  position: absolute;
  top: -10px;
  left: 0;
  display: inline-block;
  background-color: #fff;
  padding: 3px 5px;
  line-height: 0.8;
  font-size: 90% !important;
  border-radius: 3px;
  font-family: "Roboto", sans-serif;
}

.input select {
  outline: 0;
  appearance: none;
  -webkit-appearance: none;
  background-image: url("data:image/svg+xml, %3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M0 0h24v24H0z'/%3E%3Cpath d='M11.636 16.05l-5.49-5.49a.5.5 0 010-.706l.708-.708a.5.5 0 01.707 0l4.437 4.438 4.438-4.438a.5.5 0 01.638-.057l.07.057.706.708a.5.5 0 010 .707l-5.49 5.49a.498.498 0 01-.31.144h-.103a.498.498 0 01-.31-.145z' fill='%23001E50'/%3E%3C/g%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: calc(100% - 13px) 50%;
}

.form-sm {
  border: 0.008rem solid #aaa;
  border-radius: 2px;
  font-family: "Roboto", sans-serif;
  padding: 3px 6px;
  margin-bottom: 2px;
  font-size: 14px;
}

.form-control {
  flex: 1;
  padding: 16px 20px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 0.03rem solid #dfdfdf;
  font-family: "Roboto", sans-serif;
  font-size: 19px;
  color: rgb(0, 43, 79);
}

.form-control[aria-disabled] {
  background-color: #bbb;
}

.input input.form-control::placeholder {
  font-size: 90%;
  font-weight: 300;
  font-style: italic;
  color: #a3a3a3;
}

.form-control.err {
  border: 2px dashed red !important;
}

.form-control .mat-form-field-wrapper {
  padding-bottom: 0 !important;
}

input.mat-input-element {
  margin-top: 0 !important;
}

textarea {
  min-height: 90px;
  height: auto;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.bold {
  font-weight: 500;
}

.bolder {
  font-weight: 700;
}

.boldest {
  font-weight: 900;
}

.border-radius-2 {
  border-radius: 2px;
}

.border-radius-3 {
  border-radius: 3px;
}

.border-radius-4 {
  border-radius: 4px;
}

.border-radius-5 {
  border-radius: 5px;
}

.border-top {
  border-top: 1px solid #ddd;
}

.border-bottom {
  border-bottom: 1px solid #ddd;
}

.border-right {
  border-right: 1px solid #ddd;
}

.border-left {
  border-left: 1px solid #ddd;
}

.bordered {
  border: 1px solid #ddd;
}

.bg-white {
  background-color: #fff;
}

.input.iconed .form-control {
  padding-left: 50px;
}

.input.editable .form-control {
  margin-right: 50px;
}

.input-icon {
  position: absolute;
  display: flex;
  align-self: center;
  left: 18px;
  font-size: 17px;
  color: #666;
}

.input-edit {
  position: absolute;
  display: flex;
  align-self: center;
  right: 18px;
  font-size: 17px;
  color: #666;
}

.form-control label {
  position: absolute;
  bottom: calc(100% - 6px);
  display: inline-block;
  border-radius: 3px;
  padding: 2px 4px;
  line-height: 1;
  font-size: 12px;
  left: 6px;
  font-style: italic;
  background: #fff;
}

.form-control input {
  flex: 1 0;
  padding: 12px 15px 12px 10px;
  border: none;
  background-color: transparent;
  font-size: 1.12rem;
  line-height: 1.5;
  font-family: inherit;
  outline: 0px solid transparent;
  flex: 1;
}

.form-control input::placeholder {
  font-weight: 300;
  font-style: italic;
  font-size: 1.13rem;
  color: #444;
}

.rsw-ce {
  min-height: 100px;
  padding: 25px !important;
}

.rsw-btn {
  background: transparent;
  border: 0;
  color: #222;
  cursor: pointer;
  font-size: 1.2em !important;
  height: 2.4em !important;
  outline: none;
  padding: 0;
  width: 2.4em !important;
}

.dashboard-logo {
  height: 45px;
}

.dashboard-logo img {
  height: 100%;
}

.mx0 {
  padding-left: 0;
  padding-right: 0;
}

.mx5 {
  padding-left: 5px;
  padding-right: 5px;
}

.mx10 {
  padding-left: 10px;
  padding-right: 10px;
}

.mx15 {
  padding-left: 15px;
  padding-right: 15px;
}

.mx20 {
  padding-left: 20px;
  padding-right: 20px;
}

.mx30 {
  padding-left: 30px;
  padding-right: 30px;
}

.my0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.my10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.my15 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.my20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.my30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.mb0 {
  margin-bottom: 0 !important;
}

.mb5 {
  margin-bottom: 5px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb30 {
  margin-bottom: 30px;
}

.mt0 {
  margin-top: 0 !important;
}

.mt5 {
  margin-top: 5px;
}

.mt10 {
  margin-top: 10px;
}

.mt20 {
  margin-top: 20px;
}

.mt30 {
  margin-top: 30px;
}

.px0 {
  padding-left: 0;
  padding-right: 0;
}

.px5 {
  padding-left: 5px;
  padding-right: 5px;
}

.px10 {
  padding-left: 10px;
  padding-right: 10px;
}

.px15 {
  padding-left: 15px;
  padding-right: 15px;
}

.px20 {
  padding-left: 20px;
  padding-right: 20px;
}

.px30 {
  padding-left: 30px;
  padding-right: 30px;
}

.py0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.py5-i {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.py10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.py15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.py20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.py30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.pxy5 {
  padding: 5px 5px 5px 5px;
}

.pxy10 {
  padding: 10px 10px 10px 10px;
}

.pxy20 {
  padding: 20px 20px 20px 20px;
}

.pxy30 {
  padding: 30px 30px 30px 30px;
}

.mxy5 {
  margin: 5px 5px 5px 5px;
}

.mxy10 {
  margin: 10px 10px 10px 10px;
}

.mxy20 {
  margin: 20px 20px 20px 20px;
}

.mxy30 {
  margin: 30px 30px 30px 30px;
}

.pt0 {
  padding-top: 0;
}

.pt5 {
  padding-top: 5px;
}

.pt10 {
  padding-top: 10px;
}

.pt15 {
  padding-top: 15px;
}

.pt20 {
  padding-top: 20px;
}

.pt30 {
  padding-top: 30px;
}

.pb0 {
  padding-bottom: 0;
}

.pb5 {
  padding-bottom: 5px;
}

.pb10 {
  padding-bottom: 10px;
}

.pb15 {
  padding-bottom: 15px;
}

.pb20 {
  padding-bottom: 20px;
}

.pb30 {
  padding-bottom: 30px;
}

.pr5 {
  padding-right: 5px;
}

.pr10 {
  padding-right: 10px;
}

.pr20 {
  padding-right: 20px;
}

.mr5 {
  margin-right: 5px;
}

.mr10 {
  margin-right: 10px;
}

.mr20 {
  margin-right: 20px;
}

.pl5 {
  padding-left: 5px;
}

.pl10 {
  padding-left: 10px;
}

.pl20 {
  padding-left: 20px;
}

.ml5 {
  margin-left: 5px;
}

.ml10 {
  margin-left: 10px;
  margin-left: 10px;
}

.ml20 {
  margin-left: 20px;
}

.text-center {
  text-align: center;
}

ul.padded {
  padding: 10px 0;
  list-style: none;
}

ul.padded li {
  padding: 7px;
}

.profiler {
  text-align: center;
  margin: 5px 0;
}

.avatar-profiler {
  text-align: center;
  margin: 5px 0;
}

.profile-large,
.profile-larger,
.profile-largest,
.profile-medium,
.profile-small,
.profile-avatar {
  display: inline-block;
  padding: 5px;
  background-color: #fff;
  border-radius: 50%;
  border: 4px solid #9c27b0;
  background-color: #9c27b0;
  overflow: hidden;
  background-size: contain;

  background-size: cover !important;
  background-position: center !important;
  background-clip: content-box !important;
  background-origin: content-box !important;
}

.profile-large {
  height: 140px;
  width: 140px;

  min-width: 140px !important;
}

.profile-larger {
  height: 160px;
  width: 160px;

  min-width: 160px !important;
}

.profile-largest {
  height: 250px;
  width: 250px;
  min-width: 250px !important;
}

.profile-small {
  height: 60px;
  width: 60px;
  min-width: 60px !important;
}

.profile-medium {
  height: 80px;
  width: 80px;
  min-width: 80px !important;
}

.profile-avatar {
  height: 45px;
  width: 45px !important;
  min-width: 45px !important;
}

.stage-header {
  padding: 10px 0;
  border-bottom: 1px solid #aaa;
  font-size: 22px;
  font-weight: 700;
  text-transform: uppercase;
}

.show {
  display: block;
}

.hidden {
  display: none !important;
}

.MuiBox-root {
  max-width: 100% !important;
}

.card-item-flex {
  display: flex;
  flex-direction: row;
}

.dash-item-wrap {
  display: flex;
  flex-grow: 1;
  max-width: 100%;
  overflow: auto;
}

.align-base {
  align-items: baseline;
  display: flex;
}

.align-end {
  align-self: flex-start;
}


pre {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  background-color: #222;
  color: aqua;
  padding: 10px;
  line-height: 1.3;
  display: block;
  white-space: pre-wrap;
  border-radius: 4px;
  text-wrap: wrap;
  word-break: keep-all;
  overflow-wrap: break-word;
  max-width: 100%;
  box-sizing: border-box !important;
}

.date-class,
.date-span {
  font-size: 80% !important;
  font-family: inherit !important;
  font-style: italic;
  color: #888;
}

.autoform {
  margin-top: 20px;
}

.gray-bg {
  background-color: #f5f5f5;
}

.css-jh47zj-MuiButtonBase-root-MuiButton-root {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-width: 64px;
  padding: 8px 22px;
  border-radius: 4px;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #fff;
  background-color: #1976d2;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.main-message-content {
  background-color: #fff;
  padding: 20px 25px;
  font-size: 117%;
  margin-bottom: 20px;
  border-radius: 9px;
  line-height: 1.6;
  overflow-x: auto;
  word-break: break-all;
  word-wrap: break-word;
}

.main-message-content img {
  max-width: 100%;
}

.reply-content img {
  max-width: 100%;
}

.reply-content {
  padding: 10px 15px;
  margin-bottom: 10px;
  margin-top: 10px;
  position: relative;
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: break-word;
}

.reply-content p {
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: break-word;
  margin: 0;
}

.reply-content.me {
  background-color: rgb(197, 224, 242);
  margin-left: 20%;
  margin-right: 30px;
  border-radius: 10px 0 10px 10px;
}


.reply-content.me .t-arrow {
  position: absolute;
  top: 0;
  right: -20px;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid rgb(197, 224, 242);
}

.reply-content.they {
  background-color: rgb(240, 225, 235);
  margin-right: 20%;
  margin-left: 30px;
  border-radius: 0 10px 10px 10px;
}

@media screen and (max-width: 500px) {
  .reply-content.me {
    margin-left: 20px;
    margin-right: 20px;
  }

  .reply-content.they {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.reply-content.they .t-arrow {
  position: absolute;
  top: 0;
  left: -20px;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid rgb(240, 225, 235);
}

.min-mobile-width {
  min-width: 320px;
}

.modal-width {
  min-width: 450px;
}

.flex-row-resp {
  flex-direction: row;
}

@media screen and (max-width: 600px) {
  .flex-row-resp {
    flex-direction: column;
  }
}

@media screen and (max-width: 451px) {
  .modal-width {
    min-width: 100% !important;
    max-width: 100% !important;
  }
}

.text-counter {
  position: absolute;
  bottom: 0;
  left: 0;
  border: 1px solid #ccc;
  background-color: #fff;
  padding: 2px 8px;
  font-size: 14px;
  font-style: italic;
  border-radius: 0 0 0 4px;
}

.css-h0q0iv-MuiButtonBase-root-MuiTab-root {
  font-weight: 700 !important;
  line-height: 1.2 !important;
  min-height: 52px !important;
}

.table {
  min-width: 100%;
  display: block;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  border: 1px solid #ddd;

  font-family: "Roboto", sans-serif;
}

.table tbody {
  width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
}

.table tr {
  border-bottom: 1px solid #ddd;
}

.table td {
  padding: 10px;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.multi-input-file {
  height: 160px;
  border: 2px dashed #066f8f;
  border-radius: 6px;
  line-height: 160px;
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.multi-input-file:hover {
  border: 2px solid #034357;
}

.hidden-field {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: transparent;
  opacity: 0;
  cursor: pointer;
  border: 1px dotted red;
}

.hidden-field-label {
  display: inline-flex;
  padding: 10px 20px;
}

.multi-input-file:hover .hidden-field-labe {
  color: #fff;
}

.prev-item {
  height: 90px;
  max-height: 90px;
  max-width: 120px;
}

.prev-item img {
  max-height: 100%;
  max-width: 100%;
}

.input-form-control {
  flex: 1;
  padding: 16px 20px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 0.03rem solid #aaa;
  font-size: 17px;
  color: rgb(0, 43, 79);
  height: auto !important;
}

.input-text-control {
  flex: 1;
  padding: 20px 20px 13px 5px;
  box-sizing: border-box;
  border: none;
  border-bottom: 5px solid #aaa;
  font-size: 1.3rem;
  color: rgb(0, 43, 79);
  height: auto !important;
  outline: 0 !important;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  height: auto !important;
}

input.input-form-control {
  outline: 0;
}

.input-form-control.grp-with-btn {
  border-radius: 5px 0 0 5px;
  border: 0.03rem solid transparent
}

input.input-form-control,
.input-text-control {
  min-width: 20px !important;
  max-width: 100%;
}

select.input-form-control,
select.input-text-control {
  max-width: 100%;
}

.input-form-control[aria-disabled],
.input-form-control[disabled],
.input-form-control:disabled {
  background-color: #bbb;
}

.pay-symb {
  font-size: 2rem;
  padding: 0 10px;
}

.input input.input-form-control::placeholder,
.input input.input-text-control::placeholder {
  font-size: 90%;
  font-weight: 300;
  font-style: italic;
  color: #a3a3a3;
}

.input-form-control.err {
  border: 2px dashed red !important;
}

.input-form-control .mat-form-field-wrapper {
  padding-bottom: 0 !important;
}

input.mat-input-element {
  margin-top: 0 !important;
}

textarea {
  min-height: 90px;
  height: auto;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.border-top {
  border-top: 1px solid #ddd;
}

.border-bottom {
  border-bottom: 1px solid #ddd;
}


.input.iconed .input-form-control {
  padding-left: 50px;
}

.input.togger .input-form-control {
  padding-right: 50px;
}

.input-icon {
  position: absolute;
  display: flex;
  align-self: center;
  left: 18px;
  font-size: 17px;
  color: #666;
}

.input-togger {
  position: absolute;
  display: flex;
  align-self: center;
  right: 18px;
  font-size: 17px;
  color: #073db9;
}

.input-form-control label {
  position: absolute;
  bottom: calc(100% - 6px);
  display: inline-block;
  border-radius: 3px;
  padding: 2px 4px;
  line-height: 1;
  font-size: 12px;
  left: 6px;
  font-style: italic;
  background: #fff;
}

.input-form-control input {
  flex: 1 0;
  padding: 12px 15px 12px 10px;
  border: none;
  background-color: transparent;
  font-size: 1.12rem;
  line-height: 1.5;
  font-family: inherit;
  outline: 0px solid transparent;
  flex: 1;
}

.input-form-control input::placeholder {
  font-weight: 300;
  font-style: italic;
  font-size: 1.13rem;
  color: #444;
}

.rsw-ce {
  min-height: 100px;
  padding: 25px !important;
}

.rsw-btn {
  background: transparent;
  border: 0;
  color: #222;
  cursor: pointer;
  font-size: 1.2em !important;
  height: 2.4em !important;
  outline: none;
  padding: 0;
  width: 2.4em !important;
}

.rsw-btn {
  background: transparent;
  border: 0;
  color: #222;
  cursor: pointer;
  font-size: 1.2em !important;
  height: 2.4em !important;
  outline: none;
  padding: 0;
  width: 2.4em !important;
}

.css-1hju3x6-MuiPaper-root-MuiDialog-paper {
  border-radius: 20px !important;
}

.news-banner {
  width: 100%;
  margin: 10px 0;
}

.news-banner img {
  max-width: 100%;
  border-radius: 5px;
}

.news-thumb {
  width: 100%;
  margin-bottom: 10px;
  max-height: 240px;
  min-height: 200px;
  border: 1px solid #aaa;
  overflow: hidden;
}

.news-thumb img {
  max-width: 100%;
  border-radius: 5px;
}

.banner-section {
  display: flex;
  flex-direction: column;
}

.image_preview {
  display: flex;
  width: 100%;
  margin: 10px;
}

.image_preview img {
  max-width: 100% !important;
}

.in-flex {
  display: inline-flex !important;
}

.inline-block {
  display: inline-block !important;
}

.force-flex {
  display: flex !important;
  width: 70% !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
}

.force-inline-flex {
  display: flex !important;
  width: auto;

  left: 0;
  transform: translateX(0%);
}

.force-hide {
  display: none !important;
}

.force-show {
  display: block !important;
}

ins {
  color: #ffa;
  text-decoration: none;
}

.youtube-video-container {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.youtube-video-container::after {
  display: block;
  content: "";
  padding-top: 56.25%;
}

.youtube-video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.news-main {
  line-height: 1.5;
  word-break: break-all;
  margin: 30px 0;
}

.news-main img {
  max-width: 100%;
}

.news-related {
  margin-top: 20px;
  background-color: #fafafa;
  padding: 20px;
}

.news-related h2 {
  margin-bottom: 20px;
}

.gallery-modal.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%),
    0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
  margin: 0 !important;
  max-height: calc(100% - 4px) !important;
  max-width: calc(100% - 4px) !important;
  height: 100%;
  width: 100%;
}

.gallery-modal-width {
  width: 100%;
  height: 100%;
}

.dashboard-pane {
  padding: 0px;
}

.block {
  display: block !important;
  width: 100%;
}

.gallery-modal {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 1000000000 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  align-items: stretch;
}

.gallery-carousel-container {
  position: relative;
  width: 75%;
  flex-grow: 1;
}

.gallery-comments {
  position: relative;
  background-color: #fff;
  flex-grow: 1;
  width: 25%;
}

.gallery-comments-container {
  overflow-y: auto;
  padding: 20px 20px 80px 20px;
  position: relative;
  display: block;
  height: 100%;
}

.gallery-comments-meta {
  position: absolute;
  bottom: 0;
  padding: 20px;
  background-color: #ccc;
  width: 100%;
}

.gallery-description {
  padding: 10px 0;
  line-height: 1.5;
  font-size: 1.2rem;
}

.gallery-close {
  position: absolute;
  top: 20px;
  left: 40px;
  line-height: 50px;
  height: 50px;
  width: 50px;
  text-align: center;
  color: #fff;
  font-size: 20px;
  border: 1px solid #555;
  z-index: 1000 !important;
  text-shadow: 1px 1px 1px #000;
  cursor: pointer;
}

.gallery-close:hover {
  background-color: #000;
  color: red;
}

.actions-pane {
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 25000000000000000;
}

.actions-pane a {
  display: inline-block;
  padding: 6px;
  color: #fff;
  font-size: 20px;
}

.carousel-overlay {
  background-color: #000;
  opacity: 0.95;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}

.loadex {
  z-index: 980000000000;
}


.w100 {
  width: 100% !important;
}



.input-form-control-file {
  opacity: 0.1;
}

.prev-line {
  padding: 8px 0;
  border-bottom: 1px dashed #ddd;
  font-size: 1.2rem;
}

.alert-container {
  border-radius: 15px;
  background-color: #fff;
  min-width: 100%;
  max-width: 100%;
  overflow: hidden;
}

@media screen and (max-width: 450px) {
  .alert-container {
    min-width: 100%;
    max-width: 100%;
  }
}

.head-area {
  padding: 30px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 3rem;
}

.message-area {
  padding: 20px;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100% !important;
}

.button-area {
  padding: 0;
  background-color: #fefefe;
  border-top: 1px solid #ddd;
  overflow: hidden;
  width: 100%;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  border-radius: 15px !important;
  margin: 32px 10px !important;
  min-width: 400px !important;
}

@media screen and (max-width: 600px) {
  .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    max-width: calc(100% - 20px) !important;
  }
}

@media screen and (max-width: 380px) {
  .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    min-width: calc(100% - 20px) !important;
  }
}

.bg-success {
  background-color: #55996e;
  color: #fff;
}

.bg-error {
  background-color: #e11f1f;
  color: #fff;
}

.bg-info {
  background-color: #777;
  color: #fff;
}

.color-info {
  color: #444;
}

.color-error {
  color: #e11f1f;
}

.color-success {
  color: #55996e;
}

.btn {
  padding: 10px;
  border: 1px solid #bbb;
}

.hover-act:hover {
  background-color: #000 !important;
}


.max-width-input {
  max-width: 100%;
  width: 100%;
  box-sizing: border-box !important;
  position: relative;
  max-width: 100%;
  overflow: hidden !important;
  border: 2px dashed limegreen;
  background-color: limegreen;
  color: #fff;
  height: 40px;
  border-radius: 5px;
  cursor: pointer;
}

.max-width-input:hover {
  background-color: #888;
}

.max-width-input label {
  cursor: pointer !important;
  position: absolute;
  height: 100%;
  width: 100%;
  line-height: 40px;
  color: #fff;
  text-align: center;
  display: block;
  top: 0;
  left: 0;
  font-size: 1.01rem;
}

.custom-input-file {
  cursor: pointer;
  max-width: 100%;
  min-height: 100%;
  position: absolute;
  left: 0;
  width: 100%;
  opacity: 0.01;
}

.dialog-actions {

  display: flex;
  align-items: start !important;
  flex-direction: column;
  box-pack: start !important;
  -webkit-box-align: start !important;
  align-items: start !important;
  -webkit-box-pack: start !important;
  -webkit-flex-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: start !important
}