.home-main {
  position: relative;
  background: url(/public/images/bgx.jpg);
  background-size: cover;
  /*background-color: #f6cee6;
  background-color: #4a134a;*/
  overflow: hidden !important;
  z-index: 1;
  padding-bottom: 50px;


}


.home-cover {
  /*  min-height: 100vh; */
}

.home-overlay {
  background-color: #000;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 0 !important;
}



.home-overlay {
  background-position: 100% 10%;
  background-repeat: no-repeat;
  background-size: cover;
  background-size: contain;
  opacity: .6;
}

.home-content {
  display: flex;
  align-items: center;
  margin: 0 auto;
  margin-top: 40px;
  width: 90%;
  z-index: 1 !important;
  position: relative;
}

.rsw-dd {
  padding: 6px 8px !important;
}

.rsw-editor {
  margin-top: 10px !important;
}

.home-intro-div {
  color: #fff !important;
}

.home-intro-div h1 {
  font-weight: 900;
  font-size: 3.8rem;
  margin-bottom: 30px;
  line-height: 3rem;
  font-family: Playfair Display,
    serif;
}

.home-intro-div h1 em {
  font-weight: 400;
  font-size: 40%;
}

.home-intro-div h1 .em {
  font-weight: 400;
  font-style: italic;
}

.home-intro-div h1 span.colored {
  color: #c8425a !important;
}

.home-intro-div .home-summary {
  font-size: 24px;
  font-weight: 400;
  line-height: 34px;
  color: #fff !important;
}

.illustration-container {
  min-width: 40%;
  background: url(/public/icon-180.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px dashed #fff;
  height: 100%;
  min-height: 280px;
  margin-top: 0;
}

.hcta {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 0;
}

.hcta a {
  border: 1px solid #fff;
  color: #fff;
  /*   font-family: Playfair Display,
    serif; */
  font-size: .95rem;
  font-weight: 700;
  padding: 17px 30px;
  text-transform: uppercase;
  transition: .5s;
}

.hcta a:hover {
  border: 1px solid #a57a2a;
  color: #fff;
  background-color: #a57a2a;
}

@media screen and (max-width: 600px) {
  .home-content {
    display: block;
    padding: 20px 30px;
    margin-top: 10px;
    width: 100%;
  }

  .home-intro-div h1 {
    font-size: 40px;
    line-height: 1.1;
    margin-bottom: 10px;
  }

  .illustration-container {
    min-width: 100%;
    margin-top: 20px;
  }
}

.main-cta {
  margin-top: 30px;
}

.main-cta a {
  display: inline-block;
  padding: 15px 28px;
  text-transform: uppercase;
  line-height: 1.3;
  font-size: 18px;
  font-weight: 700;
}

.main-cta a.start {
  color: white;
  background-color: rgb(2, 67, 124);
  border-color: rgb(2, 67, 124);
  margin-right: 10px;
}

.main-cta a.learn {
  color: #fff;
  background-color: rgb(215, 84, 159);
  border-color: rgb(215, 84, 159);
}



.black-area {
  background-color: #000;
  color: #fff;
  padding: 30px 0;
  margin: 30px 0;
}

.contact-info-area {
  position: relative;
  padding: 0 20px 0 0;
}

.contact-info-area h2 {
  font-size: 2.3rem;
  position: relative;
}



.contact-info-area p {
  margin-top: 20px;
}

.contact-info-area h2:after {
  height: 4px;
  left: 0;
  width: 30%;
  position: absolute;
  background-color: rgb(165, 122, 42);
  bottom: -10px;
  content: " ";
}

.blue-gray-area {
  background-color: #F7FAFF;
}

.contact-area {
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.push-form {
  margin-top: -20px;
  margin-bottom: -20px;
  padding: 30px 20px;
  background-color: rgb(165, 122, 42);
  position: absolute;
  width: 100%;
}

@media screen and (max-width: 600px) {
  .push-form {
    margin-top: 0;
    position: relative;
  }
}

.blue-info {
  color: #0052fe;
}

.contact-area h3 {
  margin-bottom: 5px;
}


.home-form-control {
  flex: 1;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 0.03rem solid #000;
  font-size: 17px;
  color: #000;
  height: auto !important;
  background-color: transparent;
}


input.home-form-control {
  outline: 0;
}

input.home-form-control {
  min-width: 20px !important;
  max-width: 100%;
}

.input.hma {
  margin-bottom: 10px !important;
}

.input.iconed .home-form-control {
  padding-left: 50px;
}

select.home-form-control {
  max-width: 100%;
}

.home-form-control[aria-disabled] {
  background-color: #bbb;
}


.home-form-control::placeholder,
textarea.home-form-control::placeholder {
  font-size: 90%;
  font-weight: 400;
  font-style: italic;
  color: #333;
}

.home-form-control.err {
  border: 2px dashed red !important;
}

.input-icon.homs {
  color: #000;
}

textarea.home-form-control {
  min-height: 90px;
  height: auto;
  outline: 0;
}

button.hm-contact-btn {
  outline: 0;
  background-color: #000;
  color: #fff;
  padding: 12px 35px;
  border: 1px solid #000;
  text-transform: uppercase;
}

button.hm-contact-btn:hover {
  background-color: #444;
}

.parp {
  margin-bottom: 20px;
}

ul.cont-list {
  list-style-type: none;
  padding: 20px 0;
  margin: 0;
}

ul.cont-list li {
  display: inline-block;
  margin-right: 15px;
}

ul.cont-list li a {
  display: inline-block;
  font-size: 35px;
  color: rgb(165, 122, 42);
  transition: .5s;
}

ul.cont-list li a:hover {
  color: #fff;
}


.dark-overlay {
  background-color: #000;
  height: 100%;
  position: absolute;
  width: 100%;
  opacity: 0.85;
}


@media screen and (max-width: 600px) {
  .contact-info-area {
    position: relative;
    padding: 0 0 0 0;
  }

  .contact-info-area h2 {
    font-size: 2rem;
    position: relative;
    text-align: center;
    line-height: 1.15;
  }

  .contact-info-area p,
  .parp {
    text-align: center;
  }


}

.pg-intro-area {
  background-color: #000;
  background: url(/public/images/bgx.jpg);
  background-attachment: fixed;
  background-size: cover;
  position: relative;
  z-index: 1;
}

.pg-intro-area h1 {
  color: #fff;
}

.pg-intro-area p {
  line-height: 1.7;
  color: #fff;
}

.thinking-container {
  padding: 50px 0;
  background-color: #fafafa;
}


.thinking-desc {
  margin-bottom: 10px;
}

.thinking-desc h2 {
  font-size: 38px;
  font-weight: 900;
  position: relative;
  margin-bottom: 20px;
}

.thinking-desc h2:after {
  height: 4px;
  left: 0;
  width: 30%;
  position: absolute;
  background-color: rgb(165, 122, 42);
  bottom: -10px;
  content: " ";
}

.thinking-desc p {
  color: #888;
  margin-top: 30px;
}

.info-text-area {
  position: relative;
  padding-bottom: 30px;
}

.info-text-area::before {
  position: absolute;
  font-family: FontAwesome;
  content: "\f10d";
  top: -20px;
  left: -30px;
  color: #444;
  font-size: 50px;
  z-index: 2;
}

.info-text-area::after {
  position: absolute;
  font-family: FontAwesome;
  content: "\f10e";
  bottom: -20px;
  right: -30px;
  color: #444;
  font-size: 50px;
  z-index: 2;
}

.pad-container {
  border: 4px dashed rgb(165, 122, 42);
  padding: 30px 40px;
  display: flex;
  flex-direction: column;
  position: relative;

}



.pad-icon {
  font-size: 20px;
  height: 50px;
  width: 50px;
  color: #fff;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: rgb(165, 122, 42);
  border-radius: 50%;
  transition: .7s;

}

.pad-container:hover .pad-icon {
  background-color: #000;
}

.pad-head {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.pad-text {
  margin-left: 15px;
}

.pad-text .sub {
  font-size: 0.7rem;
  font-weight: 900;
  text-transform: uppercase;
  color: rgb(165, 122, 42);
}

.pad-text h3 {
  font-size: 1.1rem;
  font-weight: 900;
}

.thinking-banner {
  flex: 1 1;
  padding: 0px 40px;
}

.thinking-banner img {
  max-width: 100%;
}

.pad-container p {
  font-size: 1.4rem;
  color: #000;
  font-weight: 200;
  font-style: italic;
  line-height: 1.5;
  font-family: 'Playfair Display', serif;
}

.pxy20-resp {
  padding: 20px;
}

@media screen and (max-width: 500px) {
  .pxy20-resp {
    padding: 10px;
  }
}

.home-features,
.home-pricing,
.home-faq,
.home-contact-us,
.message-area-container {
  padding: 50px 0;
}

.section-title {
  padding: 30px 20px 40px 20px;
  text-align: center;
}



.section-title.medium {
  padding: 10px 20px 40px 20px;
  text-align: center;
}

.medium-section {
  padding-top: 10px !important;
}

.section-title h2 {
  text-align: center;
  font-size: 2.6rem;
  font-weight: 900;
  margin-bottom: 50px;
}


@media screen and (max-width: 600px) {
  .section-title {
    padding: 20px 10px 20px 10px;
    text-align: center;
  }

  .section-title h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
}

.feature-container {
  min-height: 200px;
  display: block;
  flex-direction: column;
  text-align: center;
  padding: 20px 0;
  border-radius: 6px;
  box-shadow: 0px 4px 2px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 2px 6px 0px rgba(0, 0, 0, 0.12);
  overflow: hidden;

  background: linear-gradient(20deg, #efefef 40%, rgba(0, 0, 0, 0) 30%), radial-gradient(farthest-corner at 0% 0%, #fefefe 70%, #fafafa 70%);

}

.feature-container:nth-last-of-type(3n) {
  border-bottom: 1px solid transparent;
}

.feature-container:hover {
  box-shadow: 0px 11px 55px 0px rgba(0, 0, 0, 0.07),
    0px 13px 18px 0px rgba(0, 0, 0, 0.15);
}

.feature-icon {
  height: 80px;
  width: 80px;
  line-height: 80px;
  /* 
  border: 1px solid #ddd; */
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 60px;
}

.feature-icon img {
  height: 100%;
}

.feature-text {
  margin-top: 20px;
  font-size: 21px;
  font-weight: 700;
  line-height: 1.3;
  padding: 0 20px;
  margin-bottom: 10px;
  font-weight: 900;
  font-family: Playfair Display, serif;
}

.feature-link {
  line-height: 1.6;
}

.feature-link a {
  display: block;
  font-weight: 900;
}

.abs-top {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 300px;
  background: url(/public/images/profile-topper.jpg);
  z-index: 5;
  background-repeat: no-repeat;
  background-size: contain;
}

.picture-card-width {
  min-width: 320px;
  max-width: 320px;
}

.hd-intro-div {
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 20px;
  z-index: 6;
}

.profile-header {
  color: #fff;
  flex-grow: 1;
  padding: 0 0 0 30px;
}

.profile-main {
  position: relative;
  background-size: contain;
  background-color: #fff;
  background-repeat: no-repeat;
  /*background-color: #4a134a;*/
  z-index: 1;
  padding-top: 150px;
}

.profile-top-main {
  display: flex;
  position: relative;
  z-index: 2;
  border-radius: 6px;
  padding-bottom: 40px;
  z-index: 5;
}

.profile-side-card {
  background-color: #fafafa;
  min-height: 500px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 5px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  z-index: 5;
}

.profile-detail-card {
  flex-grow: 1;
  padding: 90px 10px 10px 30px;
  overflow: hidden;
  z-index: 1;
}

.profile-image-container {
  width: 100%;
  overflow: hidden;
}

.profile-image-container img {
  width: 100%;
  max-width: 100%;
}

.clear-area {
  margin-top: 80px;
}

.gray-top-area {
  color: #fff;
  margin-bottom: 40px;
}