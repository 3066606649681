.z-high {
  z-index: 10;
}

.full-main-height {
  min-height: 100vh;
}

@media screen and (max-width: 600px) {
  .page-main {
    min-height: auto;
    padding-top: 0px;
    width: 100%;
  }
}

ul.social {
  list-style-type: none;
}

ul.social li {
  display: inline-block;
  font-size: 35px;
  padding-right: 20px;
}

ul.social li a {
  color: #05b57a;
}


.g0,
.g1,
.g2,
.g3,
.g4,
.g5,
.g6,
.g7,
.g8 {
  /*color: #000;*/
  transition: .5s ease-in-out;
}

.g0 {
  background-color: #dca2ed;
}

.g1 {
  background-color: #db5e05;
}

.g2 {
  background-color: #81b632;
}

.g3 {
  background-color: #037488;
}

.g4 {
  background-color: #eb0583;
}

.g5 {
  background-color: #0c0110;
}

.g6 {
  background-color: #772b8f;
}

.g7 {
  background-color: #05538b;
}

.g8 {
  background-color: #fbec17;
}

.bg-grax {
  background: -webkit-linear-gradient(20deg, #bbb 40%, rgba(0, 0, 0, 0) 30%),
    -webkit-radial-gradient(farthest-corner at 0% 0%, #ccc 70%, #ddd 70%);
  background: -o-linear-gradient(20deg, #bbb 40%, rgba(0, 0, 0, 0) 30%),
    -o-radial-gradient(farthest-corner at 0% 0%, #ccc 70%, #ddd 70%);
  background: -moz-linear-gradient(20deg, #bbb 40%, rgba(0, 0, 0, 0) 30%),
    -moz-radial-gradient(farthest-corner at 0% 0%, #ccc 70%, #ddd 70%);
  background: linear-gradient(20deg, #bbb 40%, rgba(0, 0, 0, 0) 30%),
    radial-gradient(farthest-corner at 0% 0%, #ccc 70%, #ddd 70%);
}

.bgx {
  background-image: radial-gradient(ellipse farthest-corner at 0 140%,
      rgb(92, 114, 102) 10%,
      rgb(93, 141, 176) 40%,
      #849894 70%) !important;
  color: white;
}

.bga {
  background: -webkit-linear-gradient(20deg, #efefef 40%, rgba(0, 0, 0, 0) 30%),
    -webkit-radial-gradient(farthest-corner at 0% 0%, #fefefe 70%, #fafafa 70%);
  background: -o-linear-gradient(20deg, #efefef 40%, rgba(0, 0, 0, 0) 30%),
    -o-radial-gradient(farthest-corner at 0% 0%, #fefefe 70%, #fafafa 70%);
  background: -moz-linear-gradient(20deg, #efefef 40%, rgba(0, 0, 0, 0) 30%),
    -moz-radial-gradient(farthest-corner at 0% 0%, #a95050 70%, #fafafa 70%);
  background: linear-gradient(20deg, #efefef 40%, rgba(0, 0, 0, 0) 30%),
    radial-gradient(farthest-corner at 0% 0%, #fefefe 70%, #fafafa 70%);
}


.content-placer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
  padding: 30px;
}

@media screen and (max-width: 600px) {
  .home-main {
    height: auto;
  }
}



.pick-cover {
  position: relative;
}

.pick-reset {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}



.load-error {
  padding: 30px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.load-error-icon {
  font-size: 2.6em;
}

.sm-hide {
  display: inherit;
}

@media screen and (max-width: 500px) {
  .sm-hide {
    display: none !important;
  }
}

.timer-span {
  font-weight: 900;
  line-height: 1;
}

.score-cover {
  padding: 10px 20px 5px 20px;
}

.score-line {
  padding-top: 6px;
}

.bordered {
  border: 1px solid #ddd !important;
}

.green {
  color: rgb(86, 136, 94);
}

.red {
  color: rgb(243, 0, 0);
}

.explanation-space {
  padding: 10px 20px;
  background-color: rgb(214, 231, 218);
  margin-top: 20px;
  border-radius: 5px;
}

.explanation-space h3 {
  margin-bottom: 10px;
  font-weight: 700;
  padding: 0;
}

.explanation-space p {
  margin: 10px 0;
  padding: 0;
}

.score-line {
  display: flex;
  border-top: 1px dashed #ddd;
  padding: 8px 0;
  flex-direction: row;
  align-items: center;
}


.auth-logo-space {
  margin: 65px 0 20px;
}

.auth-logo-space a.logo {
  display: inline-block;
  height: 50px;
}

.auth-logo-space a.logo img {
  height: 100%;
}

ul.flat-nav {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
}

ul.flat-nav li {
  list-style-type: none;
  display: inline-flex;
  align-content: stretch;
}

ul.flat-nav li a,
ul.flat-nav li button.button-link {
  display: inline-flex;
  align-items: center;
  border: 1px solid transparent;
  background-color: transparent;
  outline: 0;
  padding: 14px 35px;
  color: #333;
  font-weight: 600;
}

ul.flat-nav li a.active,
ul.flat-nav li a:hover,
ul.flat-nav li button.button-link:hover,
ul.flat-nav li button.button-link.active {
  border: 1px solid #ddd;
  background-color: #ddd;
}

.card-pane {
  background-color: #fff;
  border-radius: 8px;
  border-radius: 4px;
  box-shadow: 0 2px 1px -1px #0003, 0 1px 1px 0 #00000024, 0 1px 3px 0 #0000001f;
  color: #000000de;
  max-width: 400px;
  min-width: 400px;
  overflow: hidden;
  transition: box-shadow .3s cubic-bezier(.4, 0, .2, 1) 0ms;
}

@media screen and (max-width: 400px) {
  .card-pane {
    min-width: 100%;
  }
}

.button-link {
  background-color: transparent;
  border: 1px solid transparent;
  outline: 0;
  color: #2871FE;
}

.button-link:disabled,
.button-link[disable] {
  color: #888;
}

.txt-xsm {
  font-size: 80%;
}

.txt-sm {
  font-size: 90%;
}

.txt-normal {
  font-size: 100%;
}

.txt-lg {
  font-size: 110%;
}

.account-page-container {
  position: relative;
  min-height: calc(100vh / 1.4);
}

.news-card {
  height: 370px;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  color: #444;
  background-color: #fff;
  transition: 0.5s ease-in-out;
}

.news-card.member-card {
  min-height: 310px;
  height: 310px;
}

.gallery-card {
  height: 270px;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  color: #444;
  background-color: #fff;
  transition: 0.5s ease-in-out;
  position: relative;
}

.src_pane {
  overflow-y: hidden;
  display: block;
  background-color: #d4bdd3;
  width: 100%;
  position: relative;
  height: calc(100% - 30px);
  position: relative;
}

.src_pane img {
  object-fit: contain;
}

.iframe-box {
  max-width: 100%;
  padding-top: 56.25%;
  position: relative;
  width: 100%;
  height: 100%;
}

.iframe-box iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.thumb_pane {
  overflow-y: hidden;
  display: block;
  background-color: #000;
  /* */
  width: 100%;
  position: relative;
  height: 60%;
  position: relative;
}

.news-card.member-card > .thumb_pane {
  height: 90%;
}

/* .thumb_pane::after {
  position: absolute;
  height: 50%;
  width: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  content: "---";
  z-index: 0;
} */

.thumb_pane img {
  position: relative;
  width: 100%;
}

.news_thumb {
  z-index: 1;
}

.cat-span {
  position: absolute;
  z-index: 2;
  bottom: 6px;
  left: 6px;
}

.cat-span a {
  padding: 5px 6px;
  background-color: #000;
  color: #fff;
  font-size: 78%;
  text-transform: uppercase;
  font-weight: 700;
}

.cat-span a:hover {
  background-color: #05b57a;
}

.edit-span {
  position: absolute;
  z-index: 2;
  top: 6px;
  right: 6px;
}

.edit-span a {
  padding: 7px 8px;
  background-color: red;
  color: #fff;
  line-height: 1;
  font-size: 98%;
  text-transform: uppercase;
  font-weight: 700;
  display: inline-block;
  border-radius: 3px;
  border: 1px solid red;
}

.edit-span a:hover {
  background-color: #fff;
  color: red;
}

.date-space {
  font-size: 85%;
  color: #888;
}

.color-white {
  color: #fff;
}

.color-black {
  color: #333;
}

.news-summary {
  display: hidden;
  position: relative;
  padding: 10px 0;
  height: auto;
  z-index: 0;
  transition: all .5s ease-out;
}

.news-card a.rdm {
  border: 1px solid #000;
  color: #000;
  line-height: 1;
  padding: 4px 5px;
  border-radius: 3px;
  font-size: 80%;
  font-weight: 600;
}

.news-card a.rdm:hover {
  background-color: #000;
  color: #fff;
}

.news_content_space {
  position: relative;
  flex: 1 1;
  padding: 10px;
  transition: .5s ease-in-out;
}

.news_content_space h3 {
  font-weight: 700;
  line-height: 1.2;
  font-size: 1.1rem;
  padding-top: 1px;
  font-family: "Work Sans" !important;
}

.news_content_space h3 a {
  color: #444;
}

.news_content_space h3 a:hover {
  color: #880255;
  text-decoration: underline;
}

.react-span {
  display: flex;
  color: #777;
  align-items: center;
  margin-bottom: 0px;
  padding: 2px 0;
  z-index: 0;
  background-color: #fff;
}

.react-span button,
.react-btn {
  padding: 4px !important;
}

.react-icon {
  font-size: 85% !important;
}

.react-span .count-span {
  font-size: 85%;
}

.main-react-span {
  display: flex;
  color: #777;
  align-items: center;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 10px;
  justify-content: space-around;
  font-size: 115% !important;
}

.main-react-span button {
  font-size: 1.09rem;
}

.main-news-banner {
  min-height: 450px;
  overflow: hidden;
  position: relative;
  background-size: cover;
  z-index: 0;
}

.main-news-banner img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.feature-card-header {
  padding: 16px 20px;
  color: #fff;
  background-color: #043d7c;
  font-weight: 700;
  font-size: 1em;
}

.event-meta {
  padding: 10px;
  display: flex;
  align-items: center;
}

.about-container {
  padding: 20px;
  display: flex;
  flex-direction: row;
  position: relative;
  min-height: 100%;
}



.page-side-nav {
  /*  background-color: #eeeeee; */
  display: flex;
  flex-direction: column;
  min-width: 200px;
  overflow: hidden;
  z-index: 90;
  box-shadow: none;
  background-color: transparent;
  margin-right: 15px;
}

.main-about-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0;
}

ul.about-nav {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  list-style-type: none;
}

ul.about-nav li {
  display: flex;
  flex-direction: column;
  margin-bottom: 2px;
}

ul.about-nav li a {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  font-weight: 700;
  font-size: 1.2rem;
  color: #444;
  text-align: right;
  border-radius: 5px;
}

ul.about-nav li a:hover,
ul.about-nav li a.active {
  background-color: #aaa;
  color: #000;
}


@media screen and (max-width: 600px) {
  .about-container {
    padding: 10px 0;
    flex-direction: column;
  }

  .page-side-nav {
    top: 100px;
    display: none;
    position: absolute;
    background-color: #fff;
    width: 100%;
    min-width: 100%;
    box-shadow: 0 19px 38px rgba(0, 0, 0, .3), 0 15px 12px rgba(0, 0, 0, .22);
    margin-right: 0;
  }
}

.banner-section {
  display: flex;
  overflow: hidden;
}

.image_preview {
  max-width: 40%;
  height: 200px;
}

.image_preview img {
  height: 100%;
  max-height: 100%;
  width: auto;
}

.picture-card {
  max-width: 400px;
  border-radius: 8px;
  overflow: hidden;
}

.picture-card img {
  max-width: 100%;
  border-radius: 8px;
}

@media screen and (max-width: 600px) {
  .picture-card {
    min-width: 100%;
    max-width: 100%;
  }
}

.highlighted-container {
  background-color: #111;
  min-height: 360px;
  height: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
}


@media screen and (max-width: 560px) {
  .highlighted-container {
    min-height: 250px;
  }
}

.highlight-banner {
  overflow: hidden;
  position: relative;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;

  object-fit: contain;
  z-index: 0;
  text-align: center;
}

.highlight-banner img {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.highlight-signal {
  position: absolute;
  top: 0;
  width: 100%;
  padding: 10px 15px;
  z-index: 2;
  /**/
  background-image: linear-gradient(180deg,
      rgba(0, 0, 0, 0.66),
      rgba(0, 0, 0, 0.5) 50%,
      transparent);
  z-index: 9;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.signal-box {

  font-weight: 700;
  font-size: 1.3rem;
}

.signal-icon {
  color: rgb(255, 81, 18) !important;
  font-size: 0.9rem;
}

.signal-text {
  color: orange !important;
}

.highlight-details {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 10px 20px 10px 20px;
  z-index: 1;
  background-image: linear-gradient(0deg,
      rgba(0, 0, 0, 0.9),
      rgba(0, 0, 0, 0.9),
      rgba(16, 1, 19, 0.75) 60%,
      transparent);
}

.highlight-details .h1 {
  font-size: 27px;
  line-height: 1.2;
  color: #fff;
  font-weight: 700;

}

.highlight-details .h2,
.highlight-details .h2 a {
  font-size: 21px;
  line-height: 1.2;
  color: #fff;
  font-weight: 500;
}

.highlight-details .h3,
.highlight-details .h3 a {
  font-size: 18px;
  line-height: 1.2;
  color: #fff;
  font-weight: 500;
}

.highlight-details .h1 a {
  color: #fff;
}

@media screen and (max-width: 700px) {
  .highlight-details .h1 {
    font-size: 25px;
  }

  .highlight-details .h2 {
    font-size: 19px;
  }
}

@media screen and (max-width: 600px) {
  .highlight-details .h1 {
    font-size: 22px;
  }
}

@media screen and (max-width: 400px) {
  .highlight-details .h1 {
    font-size: 19px;
  }
}

.meta-span {
  color: #ffff6a;
  padding: 5px 0 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: 1px solid #777;
  margin-top: 10px;
}


.container-pad-v {
  padding: 50px 0;
  z-index: 3;
  position: relative;
}

.margin-auto {
  margin: 0 auto;
}

.w-50-resp {
  width: 50%;
}

.w-60-resp {
  width: 60%;
}

.w-70-resp {
  width: 70%;
}

.w-80-resp {
  width: 80%;
}

@media screen and (max-width: 600px) {

  .w-50-resp,
  .w-60-resp,
  .w-70-resp,
  .w-80-resp {
    width: 100%;
  }
}


.ipo {
  font-size: 15px !important;
  line-height: 19px !important;
  color: rgb(165, 122, 42);
  font-family: 'Source Sans 3', sans-serif !important;
}

.testimony-cover {
  position: relative;
  border-radius: 10px;
  margin: 60px 20px;
  z-index: 1;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 960px) {
  .testimony-cover {
    margin: 30px 10px;
  }
}

.testimony-space {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media screen and (max-width: 800px) {
  .testimony-space {
    flex-direction: column;
  }
}

.testimony-overlay {
  position: absolute;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  background-color: #67f303;
  opacity: 0.14;
  z-index: 0;
}

.testimony-overlay-quote {
  position: absolute;
  width: 70px;
  height: 70px;
  background-color: rgb(1, 115, 209);
  top: -35px;
  color: #fff;
  font-size: 36px;
  line-height: 75px;
  text-align: center;
  font-weight: 900;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 3;
  border-radius: 50%;

}




.testimony-cover:after {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  border-left-color: #67f303;
  opacity: 0.15;
  border-width: 20px;
  margin-top: -20px;
}



@media screen and (max-width: 800px) {
  .testimony-cover:after {
    top: calc(100% + 20px);
    left: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(136, 183, 213, 0);
    border-top-color: #67f303;
    opacity: 0.15;
    border-width: 20px;
    margin-left: -20px;
  }
}

.testimony-content {
  padding: 37px;
  z-index: 1;
  position: relative;
  line-height: 1.4;
  font-size: 1.5rem;
  color: #fff;
  text-align: center;
  margin-top: 20px;
}

@media screen and (max-width: 800px) {
  .testimony-content {
    padding: 17px;
    line-height: 1.4;
    font-size: 1.3rem;
  }
}

.profile-imager {
  margin: 10px 30px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.pg-title {
  color: rgb(165, 122, 42);
  font-weight: 900;
  font-size: 1.2rem;
  font-family: 'Playfair Display', serif;
  text-align: center;
  font-style: italic;
  margin-top: 20px;
}

.register-space,
.login-space {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
}

.register-cover,
.login-cover {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  position: relative;
  z-index: 1;
}

ul.reakticons {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

ul.reakticons li {
  display: inline-block;
}

ul.reakticons li a {
  display: block;
  color: #fff;
  font-size: 1.3rem;
  padding: 4px 6px;
}


button.more,
a.more {
  border: 2px solid #333;
  color: #333;
  font-size: .95rem;
  font-weight: 700;
  padding: 12px 30px;
  text-transform: uppercase;
  transition: .5s;
  outline: 0;
  background-color: transparent;
}

button.more:hover,
a.more:hover {
  border: 2px solid #333;
  color: #fff;
  background-color: #333;
}

button.more:disabled,
a.more:disabled {
  border: 2px solid #aaa;
  color: #bbb;
  background-color: #999;
}

.empty-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 50px 20px;
}

.empty-icon {
  font-size: 3rem;
}

.empty-text {
  font-size: 1.4rem;
}

.video-port {
  position: relative;
  border: 2px dashed rebeccapurple;
  display: block;
  width: 100%;
}

.video-port > div {
  height: 100%;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  position: relative;
}

.video-port > video {
  width: 100%;
  max-height: 300px;
  max-width: 100%;
  object-fit: cover !important;
}

.mode-bar-speaker {
  position: absolute;
  top: 2px;
  right: 2px;
  display: inline-block;
  background-color: #000;
  padding: 5px 3px;
  font-size: 80%;
  color: #fff;
  z-index: 3;
}

.mode-bar-viewer {
  position: absolute;
  bottom: 2px;
  right: 2px;
  display: inline-block;
  background-color: #460150;
  padding: 5px 3px;
  font-size: 80%;
  color: #fff;
  z-index: 3;
}

.comment-cover {
  border-bottom: 1px solid #bbb;
  border-radius: 4px;
  background-color: #fefefe;
}

.comment-form {
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.comment-container {
  display: flex;
}

.comment-body {
  padding: 10px 20px 10px 5px;
  font-size: 0.85em;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.author-space,
.author-space a {
  font-weight: 900;
  color: #000
}


.author-space a:hover {
  text-decoration: underline;
}

.header-info-area {
  position: relative;
  display: flex;
  margin-top: 50px;
}

.profile-container {
  position: relative;
  display: flex;
  background-color: #fff;
  border-radius: 8px;
  margin-top: 10px;
  margin-bottom: 30px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  z-index: 9901;
}

.photo-side {
  width: 300px;
  min-width: 300px;
  display: flex;
  border-right: 1px solid #ddd;
  flex-direction: column;
  align-items: center;
  background-color: #e0e0e0;
  position: relative;
  z-index: 10000000001;
}

.photo-clear {
  width: 300px;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pimg-placer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -130px;
  width: 100%;
}

.other-side {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-left: 20px;
  padding: 20px;
}

.intro-side {
  display: flex;
  flex-direction: row;
  padding-left: 20px;
  padding: 20px;
  color: #fff;
  flex-grow: 1;
}

.intro-side h1 {
  font-weight: 900;
  color: #fff;
  font-size: 2rem;
  line-height: 1.2;
  margin-bottom: 10px;
}

.intro-side p {
  font-weight: 700;
  color: #fff;
  font-size: 1.1rem;
  line-height: 1.2;
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 600px) {
  .header-info-area {
    flex-direction: column;
    margin-top: 30px;
  }

  .profile-container {
    flex-direction: column;
  }

  .photo-side {
    width: 100%;
    min-width: 100%;
    display: flex;
    border-right: 1px solid transparent;
  }

  .photo-clear {
    width: 100%;
    min-width: 100%;
    display: none;
  }

  .other-side {
    padding-left: 20px;
  }

  .intro-side {
    padding-left: 0;
  }

  .intro-side h1 {
    font-size: 1.6rem;
    line-height: 1.1;
    margin-bottom: 6px;
  }

  .intro-side p {
    font-weight: 500;
  }

  .pimg-placer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0px;
    width: 100%;
  }
}

.bar-line {
  display: flex;
  align-items: center;

}

.bio-data-div {
  padding: 10px;
  margin-bottom: 20px;
  display: block;
  width: 100%;
}

.data-line {
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
}

.sub-text {
  font-size: 85%;
  font-weight: 700;
}

.sectioner {
  margin-bottom: 30px;
}

.bio-summary {
  font-weight: 700;
  margin-top: 6px;
}

.creds {
  display: flex;
  padding: 10px 0;
}

.icred {
  font-weight: 500;
  padding-right: 10px;
}

.calendar-dark-bg {
  background-color: #222;
  border-radius: 8px;
  overflow: hidden;
  margin: 20px 0;
}

.MuiDialog-root {
  z-index: 90999000009900000 !important;
}

.article-details-container {
  /* These are technically the same, but use both */
  text-wrap: wrap;
  word-break: keep-all;
  overflow-wrap: break-word;
  max-width: 100%;
  box-sizing: border-box !important
}

.article-details-container img {
  max-width: 100%;
}

.tabs-container {
  max-width: 100%;
  width: 100%;
}

.css-zx9imm-MuiButtonBase-root-MuiTab-root {
  min-width: 20px !important;
}

.dialog-header {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  padding: 15px 20px;
  border-bottom: 1px solid #ddd;
}

.dialog-header .title-head {
  font-size: 1.2rem;
  font-weight: 700;
}

.dotted-underlined {
  border-bottom: 2px dotted #ddd;

}

.white-color,
.white {
  color: #fff
}


.my-carousel {
  background-color: #160821;
  border-radius: 3px dashed;
  display: flex;
  flex-grow: 1 1;
  height: 450px;
  position: relative;
  width: 100%;
}

@media screen and (max-width: 800px) {
  .my-carousel {
    height: 400px;
  }
}

@media screen and (max-width: 700px) {
  .my-carousel {
    height: 350px;
  }
}

@media screen and (max-width: 600px) {
  .my-carousel {
    height: 300px;
  }
}

@media screen and (max-width: 500px) {
  .my-carousel {
    height: 250px;
  }
}

@media screen and (max-width: 400px) {
  .my-carousel {
    height: 250px;
  }
}

.carousel-item-container {
  align-items: center;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  height: 100%;
  justify-content: center;
  object-fit: contain;
  position: absolute;
  right: 0;
  top: 0;
  width: 100% !important;
}

.carousel-item-container img {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.caption-container {
  bottom: 0;
  height: auto;
  left: 50%;
  max-width: 100%;
  padding: 20px;
  position: absolute;
  text-shadow: 1px 1px 1px #000;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  transition: 2s ease-in-out;
  width: 100%;
}

.caption-container-overlay {
  background-color: #000;
  height: 100%;
  left: 0;
  opacity: .4;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.caption-container-text {
  color: #fff !important;
  font-size: 1.1em;
  font-weight: 900;
  position: relative;
  z-index: 2;
}

.gallery-details-cover {
  padding: 10px 20px 0 20px
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 0.7rem;
  grid-auto-flow: dense;
  z-index: 1000;
  position: relative;
}

.gal-pop {
  position: relative;
  object-fit: contain;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
  align-content: stretch;
  min-height: 280px;
  max-height: 280px;
  z-index: 1;
}

.gal-pop img {
  max-width: 100%;
  max-height: 100%;
}

.qedit {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 900000000;
}

.qedit a {
  color: red;
  display: inline-block;
  margin-left: 4px;
}

.pic-sel {
  display: inline-block;
  position: absolute;
  left: 0%;
  top: 50%;
  transform: translateX(-50%);
  transform: translateY(-50%);
  width: 100%;
  height: 100%;
  overflow: hidden;
  color: orangered;
  font-size: 30px;
  z-index: 2;
}

.pic-sel input[type="file"] {
  width: 100%;
  height: 100%;
  max-width: 100%;
  background-color: transparent;
  opacity: 0;
  cursor: pointer;
}

.css-1hju3x6-MuiPaper-root-MuiDialog-paper,
.css-hz1bth-MuiDialog-container {
  z-index: 90000 !important;
}

.float-left {
  float: left;
  padding-bottom: 10px;
}