.main-header {
  display: flex;
  flex-direction: column;
  padding: 25px 0;
  position: relative;
  z-index: 99000000 !important;
}

.header-container {
  display: flex;
  margin: 0 auto;
  align-items: center;
  position: relative;
  width: 90%;
  z-index: 10000000002;
}

.test-header {
  display: flex;
  align-items: center;
  height: 60px;
  margin-top: 0;
  padding: 0 10px;
  border-bottom: 1px solid #444;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  backdrop-filter: blur(15px);
  z-index: 100;
}

@media screen and (max-width: 600px) {
  .test-header {}
}

.home-header {
  margin-top: 0;
}

.logo {
  height: 60px;
}

.logo img {
  height: 100%;
}

.test-logo {
  height: 40px;
  margin-right: 10px;
}

.test-logo img {
  height: 100%;
}

ul.header-navigation {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: inline-flex;
  z-index: 90000;
}

.dektop-nav {
  display: inline-flex;
}

.mobile-nav {
  display: none;
}


.ham-nav {
  display: none;
  color: #fff;
  font-size: 30px;
  transition: .6s;
}

.ham-nav a {
  color: #fff;
  transition: .6s;
}

@media screen and (max-width: 600px) {
  .dektop-nav {
    display: none;
  }

  .mobile-nav {
    display: inline-flex;
  }
}


.mobile-header-bar {
  display: none;
  width: 90%;
  margin: 0 auto;
}

@media screen and (max-width: 700px) {
  .main-header {
    padding: 20px 0;
  }

  .desktop-header-bar {
    /*display: none !important;*/
  }

  .ham-nav {
    display: inline-block;
  }

  .mobile-header-bar {
    /*display: block;*/
  }
}



.mnav {
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
  color: #fff;
}

.mnav.dark {
  color: #333;
  font-size: 1.1rem;
}

ul.header-navigation {
  list-style-type: none;
  z-index: 10000000002;
}

ul.header-navigation li {
  list-style-type: none;
  display: inline-flex;
  position: relative;
}

ul.header-navigation li a {
  display: flex;
  flex-grow: 0;
  align-items: center;
  color: #fff;
  padding: 10px 12px;
  font-weight: 500;
  font-size: 1.2rem;
  transition: .3s ease-in-out;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

ul.header-navigation li a:hover {
  color: #f4421b;
}

ul.header-navigation li > ul {
  display: none;
  flex-direction: column;
  padding: 0;
  position: absolute;
  min-width: 180px;
  width: auto;
  top: 100%;
  margin: 0;
  padding: 10px;
  border-radius: 6px;
  background-color: #fefefe;
  transition: 0.3s ease-in-out;
  z-index: 10000000002;
  animation: scaler 0.5s ease-in-out;
  -webkit-animation: scaler 0.5s ease-in-out;
  /*  */
  box-shadow: 5px 5px 16px rgba(25, 19, 23, 0.2);
}

ul.header-navigation li:hover > ul {
  display: grid;
}

ul.header-navigation li > ul::after {
  position: absolute;
  content: " ";
  left: 20px;
  bottom: 97.4%;
  width: 0;
  height: 0;
  border-radius: 14px;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 14px solid #fafafa;
}

ul.header-navigation li ul li a {
  font-size: 1.15rem;
  padding: 6px 10px !important;
  font-weight: 400;
  color: #444;
  text-wrap: nowrap;
  overflow-wrap: nowrap;
  text-overflow: ellipsis;
}

ul.header-navigation li > ul li a:hover {
  color: #f4421b;
}

ul.header-navigation li.nav-more-icon {
  background-image: url("data:image/svg+xml, %3Csvg width='20' height='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M0 0h24v24H0z'/%3E%3Cpath d='M11.636 16.05l-5.49-5.49a.5.5 0 010-.706l.708-.708a.5.5 0 01.707 0l4.437 4.438 4.438-4.438a.5.5 0 01.638-.057l.07.057.706.708a.5.5 0 010 .707l-5.49 5.49a.498.498 0 01-.31.144h-.103a.498.498 0 01-.31-.145z' fill='%23001E50'/%3E%3C/g%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: calc(100% - 9px) 50%;
  padding-right: 10px;
}

.header-cta {
  display: inline-flex;
  align-items: center;
  margin-left: 10px;
}

.header-cta a {
  display: inline-block;
  padding: 7px 10px;
  line-height: 1;
  font-weight: 700;
  border-width: 2px;
  border-style: solid;
  transition: .4s ease-in-out;
  text-transform: uppercase;
}

.header-cta a.login {
  color: #fff;
  background-color: transparent;
  border-color: #fff;
}

.header-cta a.register {
  color: #a57a2a;
  background-color: transparent;
  border-color: #a57a2a;
}

.header-cta a.register:hover {
  color: white;
  background-color: #a57a2a;
  border-color: #a57a2a;
}

.header-cta a.logout {
  color: #fff;
  background-color: #f30606;
  border-color: #f30606;
}

.header-cta a.logout:hover {
  color: #fff;
  background-color: #ff6060;
  border-color: #f30606;
}

.nav-cover {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  z-index: 90;
}

ul.category-tab {
  padding: 0;
  margin: 0;
  position: relative;
  background-color: transparent;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  box-shadow: none;
  border-bottom: 3px solid rgb(66, 64, 64);
}

ul.category-tab li {
  display: flex;
}

.zero-height {
  height: 0 !important;
}

.none {
  display: none;
}

.flex {
  display: flex !important;
}

.def-sub {
  display: none;
  flex-grow: 1;
  padding: 8px 15px;
  align-self: center;
  align-items: center;
  border: none;
  color: rgb(66, 64, 64);
  background-color: transparent;
  font-weight: 600;
  font-family: "Work Sans";
  font-size: 1.1rem;
  width: 100%;
  /* 
  border: 5px solid transparent; */
  border: 3px solid rgb(66, 64, 64);

}

@media screen and (max-width: 600px) {
  ul.category-tab li {
    display: flex;
    flex-grow: 1;
    border-bottom: 1px dashed #bbb;
  }

  ul.category-tab {
    flex-direction: column;
  }

  .def-sub {
    display: flex;
  }

  ul.category-tab {
    top: 100%;
    display: none;
    position: absolute;
    width: 100%;
    background-color: #fff;
    flex-direction: column;
    transition: all .5s ease-out;
    box-shadow: 0 19px 38px rgba(0, 0, 0, .3), 0 15px 12px rgba(0, 0, 0, .22);
  }
}


ul.category-tab li a,
ul.category-tab li button {
  display: flex;
  padding: 10px 20px;
  border: none;
  background-color: transparent;
  font-weight: 500;
  font-family: "Work Sans";
  font-size: 1.15rem;
  align-items: center;
  width: 100%;
  max-width: 100%;
  line-height: 1.1;
  border: 5px solid transparent;
  color: rgb(66, 64, 64);
}

ul.category-tab.mini li a,
ul.category-tab.mini li button {
  padding: 6px 15px;
  font-size: 1rem;
}


ul.category-tab li a:hover,
ul.category-tab li a.active,
ul.category-tab li button:hover,
ul.category-tab li button.active {
  background-color: rgb(66, 64, 64);
  color: #fff;
  border: 5px solid rgb(66, 64, 64);
}


ul.mobile-header-nav {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 0;
  background-color: #000;
  border-radius: 6px;
  margin-top: 10px;
}

ul.mobile-header-nav li {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #333;
}

ul.mobile-header-nav li .list-fig {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dropper {
  display: inline-flex;
  display: inline-block;
}

.dropper a {
  color: #fff;
  display: inline-block;
  padding: 10px;
}

ul.mobile-header-nav li:last-child {
  border-bottom: none;
}

ul.mobile-header-nav li a {
  display: flex;
  flex: 1 1;
  flex-direction: row;
  padding: 10px 2px;
  color: #fff;
  font-weight: 900;
}

ul.mobile-header-nav li a:hover {
  color: rgb(165, 122, 42);
}


ul.mobile-header-nav li ul {
  margin-bottom: 5px;
}

ul.mobile-header-nav li ul li {
  border-bottom: none;
}

ul.mobile-header-nav li ul li a {
  padding: 10px;
  color: #aaa;
  font-weight: 500;
  border-radius: 4px;
}

ul.mobile-header-nav li ul li a:hover {
  background-color: #444;
  color: rgb(165, 122, 42);
}