.break-word {
  word-wrap: break-word;
}

.full-main-height {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.counter-div-dash {
  font-size: 2rem;
  font-weight: 700;
  color: #000
}

.item-div-dash .tm-span {
  margin-right: 6px;
  color: #444;
}

.item-div-dash .tmxs {
  color: rgb(177, 60, 2) !important;
  font-weight: 500 !important;
  margin-left: 1px;
}

.lh-1 {
  line-height: 1 !important;
}

.ucap {
  text-transform: uppercase;
}

.grid-card,
a.grid-card,
.grid-card a {
  padding: 15px !important;
  border-radius: 8px;
  display: flex;
  color: #333;
  border: 1px solid #ccc;
}

.grid-card:hover {
  box-shadow: 0 19px 38px rgba(90, 90, 90, 0.3),
    0 15px 12px rgba(100, 100, 100, 0.22);
}

.grid-card h2 {
  font-weight: 900;
  font-size: 1.1rem;
}

.col-bit {
  flex-wrap: 1;
  flex: 1;
  text-align: center;
  padding: 0 6px;
}

.item-div-dash {
  font-size: 1.01rem;
  font-weight: 700;
  color: #000
}

.time-icon,
.time-icon svg {
  font-size: 1.5rem;
}


.bit-col-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.col-bit {
  border-right: 1px solid #ddd;
}

.col-bit:last-of-type {
  border-right: none;
}

.dashboard-cover {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  min-height: 100vh;
}

@media screen and (max-width: 600px) {

  .dashboard-cover {
    width: 100%;
    padding: 10px;
  }
}

.page-topper {
  padding: 20px;
  background: -webkit-linear-gradient(20deg, #efefef 40%, rgba(0, 0, 0, 0) 30%),
    -webkit-radial-gradient(farthest-corner at 0% 0%, #fefefe 70%, #fafafa 70%);
  background: -o-linear-gradient(20deg, #efefef 40%, rgba(0, 0, 0, 0) 30%),
    -o-radial-gradient(farthest-corner at 0% 0%, #fefefe 70%, #fafafa 70%);
  background: -moz-linear-gradient(20deg, #efefef 40%, rgba(0, 0, 0, 0) 30%),
    -moz-radial-gradient(farthest-corner at 0% 0%, #a95050 70%, #fafafa 70%);
  background: linear-gradient(20deg, #efefef 40%, rgba(0, 0, 0, 0) 30%),
    radial-gradient(farthest-corner at 0% 0%, #fefefe 70%, #fafafa 70%);
}

.page-topper h1 {
  font-size: 1.5rem;
  line-height: 1;
}

.page-detail-container {
  padding: 0;
  min-height: calc(100vh - 140px);
}



.drawer-link a {
  font-weight: 900 !important;
  color: #444 !important;
  font-size: 1.44rem !important;
}

.drawer-link:hover {}

.list-item-text-font,
.css-tlelie-MuiListItemText-root {
  color: #000
}

.css-cveggr-MuiListItemIcon-root {
  min-width: 50px !important;
}

.css-cveggr-MuiListItemIcon-root .css-i4bv87-MuiSvgIcon-root {
  color: #000 !important;
}


.result-error {
  min-height: calc(100vh / 1.8);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.result-error .status-icon {
  font-size: 2.5rem;
}